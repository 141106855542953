import React from "react";
import cx from "classnames";

import PriceBox from "@components/PriceBox";

import * as styles from "./styles.module.css";

const Memberships = ({ className, memberships, pricePer }) => {
  return (
    <ul className={cx(className, styles.memberships)}>
      {memberships.map(membership => (
        <PriceBox
          key={membership.title}
          pricePer={pricePer}
          hyphenate
          {...membership}
        />
      ))}
    </ul>
  );
};

export default Memberships;
