import React from "react";

import Memberships from "@components/Memberships";
import BusinessBlock from "@components/BusinessBlock";
import Hero from "@components/Hero";

import * as styles from "./styles.module.css";

const PricePage = ({ title, backgroundImage, memberships, businessBlock }) => {
  return (
    <>
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
      >
        <Memberships {...{ memberships }} />
      </Hero>

      <BusinessBlock {...businessBlock} />
    </>
  );
};

export default PricePage;
