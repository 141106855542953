import React from "react";
import { graphql } from "gatsby";

import PricePage from "@components/PricePage";


const Prices = ({ data }) => {
  const { pageData } = data;

  return <PricePage {...pageData} />;
};

export const query = graphql`
  query PricePageQuery {
    pageData: sanityPricePage {
      title
      memberships {
        ...SanityPriceBoxFragment
      }
      businessBlock {
        
        content {
          ...SanityTextBoxFragment
        }
        backgroundColor {
          value
        }
        memberships {
          ...SanityPriceBoxFragment
        }
      }
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
    }
  }
`;

export default Prices;

