import React from "react";

import Text from "@components/Text";
import BoxWrapper from "@components/BoxWrapper";

import * as styles from "./styles.module.css";

const Quote = ({ className, text, author }) => {
  return (
    <BoxWrapper className={className} whiteBox={false}>
      <Text>{text}</Text>
      <Text variant="titleThree" className={styles.author}>
        {author}
      </Text>
    </BoxWrapper>
  );
};

export default Quote;
