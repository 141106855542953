import React from "react";

import TextBox from "@components/TextBox";
import Quote from "@components/Quote";
import Memberships from "@components/Memberships";
import AppStoreButtons from "@components/AppStoreButtons";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const BusinessBlock = ({
  backgroundColor,
  /*intro,
  quote,*/
  content,
  memberships,
}) => {
  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      {/*<TextBox {...intro} whiteBox={false} className={styles.textBox} />

      <Quote className={styles.quoteBox} {...quote} />*/}

      <TextBox {...content} whiteBox={false} className={styles.textBox} />

      <Memberships
        className={styles.membershipsWrapper}
        {...{ memberships, pricePer: "mnd per lisens" }}
      />

      <AppStoreButtons className={styles.buttonsWrapper} />
    </Section>
  );
};

export default BusinessBlock;
